import React, { Component } from 'react';
import { Link } from 'gatsby';

import ProfileImage from '../ProfileImage';

import Toggler from '../Toggler';

import './Header.scss';

/* eslint-disable no-underscore-dangle */
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navbarClass: ''
    };
  }

  componentDidMount() {
    this.setState({ navbarClass: `navbar-${window.__theme}` });
  }

  render() {
    const { navbarClass } = this.state;
    const navbarClassName = `navbar navbar-expand-md ${navbarClass}`;
    return (
      <nav className={navbarClassName}>
        <a className="navbar-brand" href="/">
          <ProfileImage />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/" className="nav-link" activeClassName="active">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-link" activeClassName="active">
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/blog" className="nav-link" activeClassName="active">
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link" activeClassName="active">
                Contact
              </Link>
            </li>
            <li className="nav-item theme-toggle-item">
              <Toggler onChanged={this.onThemeChanged} />
            </li>
          </ul>
        </div>
      </nav>
    );
  }

  onThemeChanged = themeName => {
    this.setState({
      navbarClass: `navbar-${themeName}`
    });
  };
}

export default Header;
