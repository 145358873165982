import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Toggler from './Toggler';

import sun from '../../../content/assets/sun.png';
import moon from '../../../content/assets/moon.png';

/* eslint-disable no-underscore-dangle */
export default class TogglerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: null
    };
  }

  componentDidMount() {
    this.setState({ theme: window.__theme });
    window.__onThemeChange = () => {
      this.setState({ theme: window.__theme });
    };
  }

  render() {
    const { theme } = this.state;
    const { onChanged } = this.props;

    return <>{theme && this.renderToggler({ onChanged, theme })}</>;
  }

  renderToggler = ({ onChanged, theme }) => (
    <Toggler
      icons={{
        checked: (
          <img
            src={moon}
            width="16"
            height="16"
            alt="Moon icon for dark theme toggle"
            role="presentation"
            style={{ pointerEvents: 'none' }}
          />
        ),
        unchecked: (
          <img
            src={sun}
            width="16"
            height="16"
            alt="Sun icon for light theme toggle"
            role="presentation"
            style={{ pointerEvents: 'none' }}
          />
        )
      }}
      checked={theme === 'dark'}
      onChange={e => {
        const themeName = e.target.checked ? 'dark' : 'light';
        /* eslint-disable*/
        onChanged && onChanged(themeName);
        window && window.__setPreferredTheme(themeName);
      }}
    />
  );
}

TogglerContainer.propTypes = {
  onChanged: PropTypes.func.isRequired
};
