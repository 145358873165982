import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const ProfileImage = () => (
  <StaticQuery
    query={graphql`
      query {
        avatar: file(absolutePath: { regex: "/avataar.png/" }) {
          childImageSharp {
            fixed(width: 50, height: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fixed={data.avatar.childImageSharp.fixed}
        style={{
          marginBottom: 0,
          width: 50,
          height: 50,
          borderRadius: `100%`
        }}
        imgStyle={{
          borderRadius: `50%`
        }}
      />
    )}
  />
);

export default ProfileImage;
