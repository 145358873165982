import React, { Component } from 'react';

import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Seo from '../components/Seo';

import './about.scss';

class About extends Component {
  render() {
    return (
      <div className="container">
        <Seo />
        <Header />
        {this.renderAboutSection()}
      </div>
    );
  }

  renderAboutSection = () => {
    const { data } = this.props;
    return (
      <div className="about-container">
        <h3>Hello There!</h3>
        <section className="d-md-flex flex-md-row about-section">
          <div className="col-md-5 profile-image-container">
            <Image className="profile-image" fluid={data.avatar.childImageSharp.fluid} />
          </div>
          <div className="col-md-1" />
          <div className="col-md-6 d-md-flex flex-md-column justify-content-center about-content">
            <p>
              I am Shubham Bhardwaj, a software engineer based in New Delhi, India. I develop
              software products which are stable, well architectured and stand out on every aspects
              from rest.
            </p>
            <p>
              Currently, I run a digital agency which specializes on web and mobile application
              development.
            </p>
            <p>
              Want to build something awesome? Have an awesome idea but don&apos;t have the
              technical knowhow? Don&apos;t worry I can help.
            </p>
            <p>
              If you wish to work with me, &nbsp;
              <span>
                <a href="/contact">contact me.</a>
              </span>
            </p>
          </div>
        </section>
        <Footer />
      </div>
    );
  };
}

About.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object
      })
    })
  }).isRequired
};

export default About;

export const pageQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/profile_image.jpg/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
