import React, { Component } from 'react';
import { Location } from '@reach/router';

import PropTypes from 'prop-types';

import './Footer.scss';

class Footer extends Component {
  render() {
    const { location } = this.props;

    const currentYear = new Date().getFullYear();
    const footerString = `© ${currentYear} Copyright, Shubham Bhardwaj. All rights reserved.`;

    let isContactPage = false;
    if (location.pathname === '/contact') isContactPage = true;
    const className = isContactPage ? 'footer contact' : 'footer';
    const mainHead = isContactPage
      ? 'Follow me elsewhere -'
      : 'Work enquiry, question or something else? ';
    return (
      <div className={className}>
        <h3>
          {mainHead}
          {!isContactPage && <a href="/contact">Contact me</a>}
        </h3>
        <ul>
          {this.renderSocialLink({
            name: 'Github',
            href: 'https://github.com/shbmbhrdwj'
          })}
          {this.renderSocialLink({
            name: 'Twitter',
            href: 'https://twitter.com/shbhmbhrdwj'
          })}
          {this.renderSocialLink({
            name: 'LinkedIn',
            href: 'https://www.linkedin.com/in/shubhambhardwaj1/'
          })}
          {this.renderSocialLink({
            name: 'StackOverflow',
            href: 'https://stackoverflow.com/users/7629550/shubham-bhardwaj'
          })}
        </ul>
        <p>{footerString}</p>
      </div>
    );
  }

  renderSocialLink = ({ name, href }) => {
    return (
      <li>
        <a href={href} target="_blank" rel="noopener noreferrer">
          {name}
        </a>
      </li>
    );
  };
}

Footer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};

/*eslint-disable */
export default props => (
  <Location>{locationProps => <Footer {...locationProps} {...props} />}</Location>
);
